export let monProducts = [
  {
    id: 5,
    name: 'bảng gỗ',
    category: 'bảng',
    des: '',
    off: 30,
    sold: 126,
    sizes: [{ size: '30cm x 10cm', price: '480.000', priceFirst: '680.000' }],
    colors: [{ color: 'blue', img: '5' }],
    otherImgs: [],
    nocolors: ['5n-1', '5n-2'],
  },
  {
    id: 4,
    name: 'vỏ gối ôm ngủ',
    category: 'vải',
    des: 'Lưu ý: Không bao gồm ruột',
    off: 30,
    sold: 126,
    sizes: [{ size: '45cm x 45cm', price: '120.000', priceFirst: '170.000' }],
    colors: [
      { color: 'red', img: '4' },
      { color: 'pink', img: '4-1' },
      { color: 'blue', img: '4-2' },
    ],
    otherImgs: ['4n-1'],
    nocolors: [],
  },
  {
    id: 3,
    name: 'thẻ gỗ daruma',
    category: 'gỗ',
    des: '',
    off: 30,
    sold: 475,
    sizes: [{ size: '10cm x 10cm', price: '95.000', priceFirst: '135.000' }],
    colors: [
      { color: 'red', img: '3-5' },
      { color: 'blue', img: '3' },
      { color: 'pink', img: '3-1' },
    ],
    otherImgs: ['3b-1'],
    nocolors: ['3-2', '3-3', '3-4'],
  },
  {
    id: 2,
    name: 'cờ gỗ để bàn',
    category: 'cờ',
    des: '',
    off: 30,
    sold: 475,
    sizes: [{ size: '13.5cm x 30cm', price: '130.000', priceFirst: '180.000' }],
    colors: [
      { color: 'blue', img: '2' },
      { color: 'pink', img: '2-1' },
      { color: 'white', img: '2-2' },
      { color: 'black', img: '2-3' },
    ],
    otherImgs: ['2b-1', '2b-2', '2b-3', '2b-4'],
    nocolors: ['2n-1', '2n-2', '2n-3', '2n-4', '2n-5', '2n-6', '2n-7', '2n-8', '2n-9', '2n-10', '2n-11', '2n-12', '2n-13', '2n-14', '2n-15'],
  },
  {
    id: 1,
    name: 'rèm noren',
    category: 'rèm',
    des: '',
    off: 30,
    sold: 233,
    sizes: [
      { size: '65cm x 100cm', price: '425.000', priceFirst: '600.000' },
      { size: '65cm x 150cm', price: '525.000', priceFirst: '750.000' },
      { size: '75cm x 150cm', price: '625.000', priceFirst: '900.000' },
      { size: '85cm x 180cm', price: '725.000', priceFirst: '1.000.000' },
      { size: '120cm x 200cm', price: '855.000', priceFirst: '1.200.000' },
      { size: '130cm x 150cm', price: '925.000', priceFirst: '1.300.000' },
      { size: '140cm x 200cm', price: '995.000', priceFirst: '1.400.000' },
    ],
    colors: [
      { color: 'blue', img: '1' },
      { color: 'red', img: '1-1' },
      { color: 'yellow', img: '1-2' },
      { color: 'black', img: '1-3' },
    ],
    otherImgs: ['1-4'],
    nocolors: [],
  },
  {
    id: 0,
    name: 'daruma',
    category: 'búp bê',
    des: 'Daruma là một thần vật bùa may mắn truyền thống của Nhật Bản mô phỏng theo Bồ Đề Đạt Ma, người sáng lập ra truyền thống Thiền tông của Phật giáo.',
    off: 30,
    sold: 129,
    sizes: [
      { size: '15cm', price: '425.000', priceFirst: '600.000' },
      { size: '10cm', price: '255.000', priceFirst: '360.000' },
      { size: '12cm', price: 'outstock', priceFirst: 'outstock' },
      { size: '19cm', price: 'outstock', priceFirst: 'outstock' },
      { size: '26cm', price: '950.000', priceFirst: '1.350.000' },
      { size: '30cm', price: '1.175.000', priceFirst: '1.680.000' },
      { size: '50cm', price: 'discuss', priceFirst: 'discuss' },
      { size: '75cm', price: 'discuss', priceFirst: 'discuss' },
    ],
    colors: [
      { color: 'red', img: '0' },
      { color: 'white', img: '0-1' },
      { color: 'black', img: '0-2' },
      { color: 'blue', img: '0-3' },
      { color: 'pink', img: '0-4' },
      { color: 'purple', img: '0-5' },
      { color: 'green', img: '0-6' },
      { color: 'yellow', img: '0-7' },
    ],
    otherImgs: ['0b1'],
    nocolors: [],
  },
]
